// src/components/MainApp.js
import React, { useState } from 'react';
import Header from './Header';
import Sidebar from './Sidebar';
import './styles/NavStyle.css';

const MainApp = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [logout, setLogout] = useState(false);

  const handleDrawerOpen = () => setOpen(true);
  const handleDrawerClose = () => setOpen(false);
  const handleLogout = () => setLogout(true);

  return (
    <div className="root">
      <Header handleDrawerOpen={handleDrawerOpen} handleLogout={handleLogout} logout={logout} />
      <Sidebar open={open} handleDrawerClose={handleDrawerClose} />
      <main className="content">
        <div className="drawerHeaderSpacer" />
        {children}
      </main>
    </div>
    
  );
};

export default MainApp;
