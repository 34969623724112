import React from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, IconButton } from '@mui/material';
import { ChevronLeft as ChevronLeftIcon } from '@mui/icons-material';
import PeopleIcon from '@mui/icons-material/People'; // Icono para Clientes
import AssessmentIcon from '@mui/icons-material/Assessment'; // Icono para Equifax
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';// Icono para Bisca
import PersonIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';
import ErrorIcon from '@mui/icons-material/Error';
import { Link } from 'react-router-dom';

const Sidebar = ({ open, handleDrawerClose }) => (
  <Drawer
    variant="persistent"
    anchor="left"
    open={open}
  >
    <div className="drawerHeader">
      <IconButton onClick={handleDrawerClose}>
        <ChevronLeftIcon />
      </IconButton>
    </div>
    <List>
      <ListItem button component={Link} to="/clientes">
        <ListItemIcon><PeopleIcon /></ListItemIcon>
        <ListItemText primary="Clientes" />
      </ListItem>
      <ListItem button component={Link} to="/equifax">
        <ListItemIcon><AssessmentIcon /></ListItemIcon>
        <ListItemText primary="Equifax" />
      </ListItem>
      <ListItem button component={Link} to="/bisca">
        <ListItemIcon><AccountBalanceIcon /></ListItemIcon>
        <ListItemText primary="Bisca" />
      </ListItem>
      <ListItem button component={Link} to="/usuario">
        <ListItemIcon><PersonIcon /></ListItemIcon>
        <ListItemText primary="Usuarios" />
      </ListItem>
      <ListItem button component={Link} to="/parametros">
        <ListItemIcon><SettingsIcon /></ListItemIcon>
        <ListItemText primary="Parametros" />
      </ListItem>
      <ListItem button component={Link} to="/excepciones">
        <ListItemIcon><ErrorIcon /></ListItemIcon>
        <ListItemText primary="Excepciones" />
      </ListItem>
    </List>
  </Drawer>
);

export default Sidebar;
