import React, { useState, useEffect } from "react";
import axios from "axios";
import {Typography,Container,TextField,Button,TableContainer,Table,TableHead,TableRow,TableCell,TableBody,Paper,Snackbar,Alert,Dialog,DialogTitle,DialogContent,DialogActions
} from "@mui/material";
import MainApp from './MainApp'; 
import './styles/PagStyle.css'; 

const Excepcion = () => {
    const [excepciones, setExcepciones] = useState([]);
    const [excepDocu, setExcepDocu] = useState("");
    const [excepNom, setExcepNom] = useState("");
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const [selectedExcepcion, setSelectedExcepcion] = useState(null);
    const [editExcepDocu, setEditExcepDocu] = useState("");
    const [editExcepNom, setEditExcepNom] = useState("");
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [addDialogOpen, setAddDialogOpen] = useState(false);

    useEffect(() => {
        axios
            .get("http://167.99.118.252:5000/api/excepciones")
            .then((response) => {
                console.log("Datos recibidos de la API:", response.data);
                setExcepciones(response.data);
            })
            .catch((error) => {
                console.error("Error al obtener los datos de la API:", error);
            });
    }, []);

    const handleAddExcepcion = async () => {
        if (!excepDocu || !excepNom) {
            setSnackbarMessage("Por favor, ingresa todos los campos.");
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
            return;
        }

        try {
            const response = await axios.post("http://167.99.118.252:5000/api/excepciones", {
                MOR_EXCDOCU: excepDocu,
                MOR_EXCNOM: excepNom,
            });
            console.log("Respuesta de la API:", response.data);

            const updatedExcepciones = await axios.get("http://167.99.118.252:5000/api/excepciones");
            setExcepciones(updatedExcepciones.data);
            setExcepDocu("");
            setExcepNom("");
            setSnackbarMessage("Excepción agregada exitosamente.");
            setSnackbarSeverity("success");
            setSnackbarOpen(true);
            setAddDialogOpen(false);
        } catch (error) {
            console.error("Error al agregar la excepción:", error);
            setSnackbarMessage(
                "Error al agregar la excepción. Por favor, intenta nuevamente más tarde."
            );
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
        }
    };

    const handleEditExcepcion = () => {
        if (!selectedExcepcion) {
            console.error("No se ha seleccionado ninguna excepción para editar.");
            return;
        }

        const updatedData = {
            MOR_EXCDOCU: editExcepDocu,
            MOR_EXCNOM: editExcepNom,
        };

        axios
            .put(
                `http://167.99.118.252:5000/api/excepciones/${selectedExcepcion.MOR_EXCID}`,
                updatedData
            )
            .then(() => {
                const updatedExcepciones = excepciones.map((excepcion) =>
                    excepcion.MOR_EXCID === selectedExcepcion.MOR_EXCID
                        ? { ...excepcion, ...updatedData }
                        : excepcion
                );
                setExcepciones(updatedExcepciones);
                setEditDialogOpen(false);
                setEditExcepNom("");
                setEditExcepDocu("");
                setSnackbarMessage("Excepción actualizada exitosamente.");
                setSnackbarSeverity("success");
                setSnackbarOpen(true);
            })
            .catch((error) => {
                console.error("Error al actualizar la excepción:", error);
                setSnackbarMessage(
                    "Error al actualizar la excepción. Por favor, intenta nuevamente más tarde."
                );
                setSnackbarSeverity("error");
                setSnackbarOpen(true);
            });
    };

    const handleDeleteExcepcion = (id) => {
        const confirmDelete = window.confirm("¿Estás seguro de que deseas eliminar esta excepción?");
        if (!confirmDelete) {
            return;
        }
        axios
            .delete(`http://167.99.118.252:5000/api/excepciones/${id}`)
            .then(() => {
                const updatedExcepciones = excepciones.filter((excepcion) => excepcion.MOR_EXCID !== id);
                setExcepciones(updatedExcepciones);
                setSnackbarMessage("Excepción eliminada exitosamente.");
                setSnackbarSeverity("success");
                setSnackbarOpen(true);
            })
            .catch((error) => {
                console.error("Error al eliminar la excepción:", error);
                setSnackbarMessage(
                    "Error al eliminar la excepción. Por favor, intenta nuevamente más tarde."
                );
                setSnackbarSeverity("error");
                setSnackbarOpen(true);
            });
    };

    const handleEditDialogOpen = (excepcion) => {
        setSelectedExcepcion(excepcion);
        setEditExcepNom(excepcion.MOR_EXCNOM);
        setEditExcepDocu(excepcion.MOR_EXCDOCU);
        setEditDialogOpen(true);
    };

    const handleAddDialogOpen = () => {
        setAddDialogOpen(true);
    };

    const handleEditDialogClose = () => {
        setEditDialogOpen(false);
        setSelectedExcepcion(null);
        setEditExcepNom("");
        setEditExcepDocu("");
    };

    const handleAddDialogClose = () => {
        setAddDialogOpen(false);
        setExcepDocu("");
        setExcepNom("");
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    return (
        <MainApp>
                <div className="drawer-header" />
                <Container className="container-styled">
                    <Typography variant="h4" className="title">Lista de Excepciones</Typography>
                    <div className="button-section">
                        <Button variant="contained" color="primary" onClick={handleAddDialogOpen}>
                            Agregar Excepción
                        </Button>
                    </div>
                    <TableContainer component={Paper}>
                        <Table className="table-styled">
                            <TableHead>
                                <TableRow>
                                    <TableCell className="table-header-cell">ID</TableCell>
                                    <TableCell className="table-header-cell">Documento</TableCell>
                                    <TableCell className="table-header-cell">Nombre</TableCell>
                                    <TableCell className="table-header-cell">Acciones</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {excepciones.map((excepcion) => (
                                    <TableRow key={excepcion.MOR_EXCID}>
                                        <TableCell>{excepcion.MOR_EXCID}</TableCell>
                                        <TableCell>{excepcion.MOR_EXCDOCU}</TableCell>
                                        <TableCell>{excepcion.MOR_EXCNOM}</TableCell>
                                        <TableCell>
                                            <Button variant="contained" color="primary" onClick={() => handleEditDialogOpen(excepcion)}>Editar</Button>
                                            <Button variant="contained" color="secondary" onClick={() => handleDeleteExcepcion(excepcion.MOR_EXCID)}>Eliminar</Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Container>
                <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                    <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>{snackbarMessage}</Alert>
                </Snackbar>
                <Dialog open={editDialogOpen} onClose={handleEditDialogClose}>
                    <DialogTitle>Editar Excepción</DialogTitle>
                    <DialogContent>
                        <TextField label="Documento" variant="outlined" value={editExcepDocu || ""} onChange={(e) => setEditExcepDocu(e.target.value)} fullWidth margin="normal" />
                        <TextField label="Nombre" variant="outlined" value={editExcepNom || ""} onChange={(e) => setEditExcepNom(e.target.value)} fullWidth margin="normal" />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleEditDialogClose} color="primary">Cancelar</Button>
                        <Button onClick={handleEditExcepcion} color="primary">Guardar Cambios</Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={addDialogOpen} onClose={handleAddDialogClose}>
                    <DialogTitle>Agregar Excepción</DialogTitle>
                    <DialogContent>
                        <TextField label="Documento" variant="outlined" value={excepDocu} onChange={(e) => setExcepDocu(e.target.value)} fullWidth margin="normal" />
                        <TextField label="Nombre" variant="outlined" value={excepNom} onChange={(e) => setExcepNom(e.target.value)} fullWidth margin="normal" />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleAddDialogClose} color="primary">Cancelar</Button>
                        <Button onClick={handleAddExcepcion} color="primary">Agregar</Button>
                    </DialogActions>
                </Dialog>
        </MainApp>
    );
};

export default Excepcion;
