import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import {
  Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, TextField, Button, IconButton, MenuItem, Snackbar, Alert, Box, Select, FormControl, InputLabel, Dialog, DialogActions, DialogContent, DialogTitle
} from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';
import MainApp from './MainApp';
import './styles/Cliente.css';
import * as XLSX from 'xlsx';

const Clientes = () => {
  const [clientes, setClientes] = useState([]);
  const [searchName, setSearchName] = useState('');
  const [searchDoc, setSearchDoc] = useState('');
  const [searchFactura, setSearchFactura] = useState('');
  const [filterMarUsu, setFilterMarUsu] = useState('Todos');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [startDate, setStartDate] = useState(new Date().toISOString().slice(0, 10));
  const [endDate, setEndDate] = useState(new Date().toISOString().slice(0, 10));
  const [selectedCliente, setSelectedCliente] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  const fetchData = useCallback(() => {
    axios.post('http://167.99.118.252:5000/api/clientes', {
      fechaDesde: startDate,
      fechaHasta: endDate,
      tipo: filterMarUsu,
      searchName,
      searchDoc,
      searchFactura
    })
      .then(response => setClientes(response.data))
      .catch(error => {
        console.error('Hubo un error al cargar los clientes:', error);
        setSnackbarOpen(true);
      });
  }, [startDate, endDate, filterMarUsu, searchName, searchDoc, searchFactura]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleSearchName = (event) => {
    setSearchName(event.target.value.toUpperCase());
  };

  const handleSearchDoc = (event) => {
    setSearchDoc(event.target.value.toUpperCase());
  };

  const handleSearchFactura = (event) => {
    setSearchFactura(event.target.value.toUpperCase());
  };

  const handleFilterMarUsuChange = (event) => {
    setFilterMarUsu(event.target.value);
  };

  const isWithinDateRange = (clientDate, startDate, endDate) => {
    const clientDateObj = new Date(clientDate).setHours(0, 0, 0, 0);
    const startDateObj = new Date(startDate).setHours(0, 0, 0, 0);
    const endDateObj = new Date(endDate).setHours(23, 59, 59, 999);
    return clientDateObj >= startDateObj && clientDateObj <= endDateObj;
  };

  const filteredClientes = clientes.filter(cliente => {
    const clientDate = new Date(cliente.MOH_fecha).toISOString().slice(0, 10);
    const matchesSearchName = searchName === '' || cliente.MOH_Nombre?.toUpperCase().includes(searchName);
    const matchesSearchDoc = searchDoc === '' || cliente.MOH_Docunu?.toUpperCase().includes(searchDoc);
    const matchesSearchFactura = searchFactura === '' || cliente.MOH_ope1?.toUpperCase().includes(searchFactura);
    const matchesMarUsu = filterMarUsu === 'Todos' || cliente.MOH_MarUsu === filterMarUsu;
    const matchesDateRange = isWithinDateRange(clientDate, startDate, endDate);
    return matchesSearchName && matchesSearchDoc && matchesSearchFactura && matchesMarUsu && matchesDateRange;
  });

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handlePrintClick = (cliente) => {
    setSelectedCliente(cliente);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(filteredClientes);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Clientes');
    XLSX.writeFile(workbook, 'Clientes.xlsx');
  };

  return (
    <MainApp>
      <Box className="gradient-background">
        <Container className="container">
          <Typography variant="h4" component="h1" gutterBottom className="title">
            Histórico de Inclusión/Exclusión de Clientes
          </Typography>
          <Typography variant="h5" component="h2" gutterBottom className="title">
            Filtros de búsqueda
          </Typography>
          <Box className="search-container">
            <Box sx={{ display: 'flex', gap: 0.5, mb: 2, width: '150vh' }}>
              <TextField label="Buscar por Nombre" variant="outlined" className="search" value={searchName} onChange={handleSearchName} sx={{ flex: 1 }} />
              <TextField label="Buscar por Nro documento" variant="outlined" className="search" value={searchDoc} onChange={handleSearchDoc} sx={{ flex: 1 }} />
              <TextField label="Buscar por Nro Factura" variant="outlined" className="search" value={searchFactura} onChange={handleSearchFactura} sx={{ flex: 1 }} />
            </Box>
            <Box className="search-filters" sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
              <FormControl className="form-control" sx={{ flex: 1 }}>
                <InputLabel>Tipo</InputLabel>
                <Select value={filterMarUsu} onChange={handleFilterMarUsuChange} label="Filtro">
                  <MenuItem value="Todos">Todos</MenuItem>
                  <MenuItem value="I">Inclusión</MenuItem>
                  <MenuItem value="E">Exclusión</MenuItem>
                </Select>
              </FormControl>
              <TextField label="Desde" type="date" variant="outlined" className="form-control" value={startDate} onChange={(e) => setStartDate(e.target.value)} InputLabelProps={{ shrink: true }} sx={{ flex: 1 }} />
              <TextField label="Hasta" type="date" variant="outlined" className="form-control" value={endDate} onChange={(e) => setEndDate(e.target.value)} InputLabelProps={{ shrink: true }} sx={{ flex: 1 }} />
            </Box>
            <Button variant="contained" style={{ backgroundColor: '#4caf50', color: '#ffffff', marginTop: 16 }} className="print-button" onClick={exportToExcel}>Descargar Excel</Button>
          </Box>
          <TableContainer component={Paper} sx={{ marginTop: 2 }}>
            <Table className="table">
              <TableHead>
                <TableRow>
                  <TableCell className="table-header-cell">Acciones</TableCell>
                  <TableCell className="table-header-cell">Fecha proceso</TableCell>
                  <TableCell className="table-header-cell">Nro documento</TableCell>
                  <TableCell className="table-header-cell">Nro Factura</TableCell>
                  <TableCell className="table-header-cell">Nombre</TableCell>
                  <TableCell className="table-header-cell">Plazo</TableCell>
                  <TableCell className="table-header-cell">Saldo</TableCell>
                  <TableCell className="table-header-cell">Monto operacion</TableCell>
                  <TableCell className="table-header-cell">Fecha Operacion</TableCell>
                  <TableCell className="table-header-cell">Vencimiento</TableCell>
                  <TableCell className="table-header-cell">Ultimo Pago</TableCell>
                  <TableCell className="table-header-cell">Atraso</TableCell>
                  <TableCell className="table-header-cell">Tipo</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredClientes.map(cliente => (
                  <TableRow key={`${cliente.MOH_AaCuen}_${cliente.MOH_Docunu}_${cliente.MOH_ope1}`} className="styled-table-row">
                    <TableCell className="table-cell">
                      <IconButton color="primary" onClick={() => handlePrintClick(cliente)}>
                        <PrintIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell className="table-cell">{new Date(cliente.MOH_fecha).toLocaleDateString()}</TableCell>
                    <TableCell className="table-cell">{cliente.MOH_Docunu}</TableCell>
                    <TableCell className="table-cell">{cliente.MOH_ope1}</TableCell>
                    <TableCell className="table-cell">{cliente.MOH_Nombre}</TableCell>
                    <TableCell className="table-cell">{cliente.MOH_Plazo}</TableCell>
                    <TableCell className="table-cell">{parseFloat(cliente.MOH_saldo1).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableCell>
                    <TableCell className="table-cell">{parseFloat(cliente.MOH_OpeMonto).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableCell>
                    <TableCell className="table-cell">{new Date(cliente.MOH_FecOper).toLocaleDateString()}</TableCell>
                    <TableCell className="table-cell">{new Date(cliente.MOH_FVenPen).toLocaleDateString()}</TableCell>
                    <TableCell className="table-cell">{new Date(cliente.MOH_FUltPag).toLocaleDateString()}</TableCell>
                    <TableCell className="table-cell">{cliente.MOH_Atrazo}</TableCell>
                    <TableCell className="table-cell">{cliente.MOH_MarUsu}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Container>
      </Box>
      
      <Dialog open={dialogOpen} onClose={handleDialogClose} maxWidth="md" fullWidth>
        <DialogTitle>Detalles del Cliente</DialogTitle>
        <DialogContent>
          {selectedCliente && (
            <div>
              <Typography variant="body1"><strong>Nro documento:</strong> {selectedCliente.MOH_Docunu}</Typography>
              <Typography variant="body1"><strong>Nro Factura:</strong> {selectedCliente.MOH_ope1}</Typography>
              <Typography variant="body1"><strong>Nombre:</strong> {selectedCliente.MOH_Nombre}</Typography>
              <Typography variant="body1"><strong>Plazo:</strong> {selectedCliente.MOH_Plazo}</Typography>
              <Typography variant="body1"><strong>Saldo:</strong> {selectedCliente.MOH_saldo1}</Typography>
              <Typography variant="body1"><strong>Monto operacion:</strong> {selectedCliente.MOH_OpeMonto}</Typography>
              <Typography variant="body1"><strong>Fecha Operacion:</strong> {new Date(selectedCliente.MOH_FecOper).toLocaleDateString()}</Typography>
              <Typography variant="body1"><strong>Vencimiento:</strong> {new Date(selectedCliente.MOH_FVenPen).toLocaleDateString()}</Typography>
              <Typography variant="body1"><strong>Ultimo Pago:</strong> {new Date(selectedCliente.MOH_FUltPag).toLocaleDateString()}</Typography>
              <Typography variant="body1"><strong>Atraso:</strong> {selectedCliente.MOH_Atrazo}</Typography>
              <Typography variant="body1"><strong>Tipo:</strong> {selectedCliente.MOH_MarUsu}</Typography>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">Cerrar</Button>
        </DialogActions>
      </Dialog>

      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity="error" sx={{ width: '100%' }}>
          Hubo un error al cargar los clientes.
        </Alert>
      </Snackbar>
    </MainApp>
  );
};

export default Clientes;
