import React from 'react';
import { Link } from 'react-router-dom';
import { AppBar, Toolbar, IconButton, Box } from '@material-ui/core';
import { ExitToApp as ExitToAppIcon, Menu as MenuIcon } from '@material-ui/icons';
import { Navigate } from 'react-router-dom';

const Header = ({ handleDrawerOpen, handleLogout, logout }) => {
  if (logout) {
    return <Navigate to="/login" />;
  }

  return (
    <AppBar position="fixed" className="appBar">
      <Toolbar>
        <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleDrawerOpen}>
          <MenuIcon />
        </IconButton>
        <Box display="flex" alignItems="center" flexGrow={1}>
          <Link to="/app" style={{ textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center' }}>
            <img src="https://www.centralshop.com.py/_next/static/media/centralshoplogo.d08fd0e3.svg" alt="Logo" style={{ width: '150px', marginRight: '20px' }} />
 
          </Link>
        </Box>
        <div style={{ marginLeft: 'auto' }}> {}
          <IconButton color="inherit" aria-label="logout" onClick={handleLogout}>
            <ExitToAppIcon />
          </IconButton>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
