import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import LoginForm from './components/LoginForm';
import MainApp from './components/MainApp';
import Clientes from './components/Clientes';
import Equifax from './components/Equifax';
import Bisca from './components/Bisca';
import Usuario from './components/Usuario';
import Parametros from './components/Parametros';
import Excepciones from './components/Excepciones';
import ProtectedRoute from './components/ProtectedRoute';

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/" element={<LoginForm />} />
        <Route path="/login" element={<LoginForm />} />
        <Route path="/app" element={<ProtectedRoute><MainApp /></ProtectedRoute>} />
        <Route path="/clientes" element={<ProtectedRoute><Clientes /></ProtectedRoute>} />
        <Route path="/equifax" element={<ProtectedRoute><Equifax /></ProtectedRoute>} />
        <Route path="/bisca" element={<ProtectedRoute><Bisca /></ProtectedRoute>} />
        <Route path="/Usuario" element={<ProtectedRoute><Usuario /></ProtectedRoute>} />
        <Route path="/Parametros" element={<ProtectedRoute><Parametros /></ProtectedRoute>} />
        <Route path="/Excepciones" element={<ProtectedRoute><Excepciones /></ProtectedRoute>} />
      </Routes>
    </Router>
  </React.StrictMode>
);

reportWebVitals();
