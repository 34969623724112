import React, { useState, useEffect } from "react";
import axios from "axios";
import { Select, MenuItem, FormControl, InputLabel, Typography, Container, TextField, Button, TableContainer, Table, TableHead, TableRow, TableCell, 
  TableBody, Paper, Snackbar, Alert, Dialog, DialogTitle,DialogContent, DialogActions} from "@mui/material";
import MainApp from './MainApp';
import './styles/PagStyle.css';


const Usuario = () => {
  const [users, setUsers] = useState([]);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [estado, setEstado] = useState("");
  const [nombre, setNombre] = useState("");
  const [apellido, setApellido] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [selectedUser, setSelectedUser] = useState(null);
  const [editUsername, setEditUsername] = useState("");
  const [editEstado, setEditEstado] = useState("");
  const [editNombre, setEditNombre] = useState("");
  const [editApellido, setEditApellido] = useState("");
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [addDialogOpen, setAddDialogOpen] = useState(false);

  useEffect(() => {
    axios
      .get("http://167.99.118.252:5000/api/users")
      .then((response) => {
        setUsers(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.error("There was an error fetching the users!", error);
      });
  }, []);
  

  const handleAddUser = () => {
    if (!username || !password || !estado || !nombre || !apellido) {
      setSnackbarMessage("Por favor, ingresa todos los campos.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return;
    }
    axios
      .post("http://167.99.118.252:5000/api/users", { username, password, estado, nombre, apellido })
      .then((response) => {
        setUsers([...users, response.data]);
        setUsername("");
        setPassword("");
        setEstado("");
        setNombre("");
        setApellido("");
        setSnackbarMessage("Usuario agregado exitosamente.");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
        setAddDialogOpen(false);
        window.location.reload();
      })
      .catch((error) => {
        console.error("Error al agregar el usuario:", error);
        setSnackbarMessage(
          "Error al agregar el usuario. Por favor, intenta nuevamente más tarde."
        );
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      });
  };

  const handleEditUser = () => {
    const updatedData = {};
    if (editUsername !== "") updatedData.username = editUsername;
    if (editEstado !== "") updatedData.Estado = editEstado;
    if (editNombre !== "") updatedData.Nombre = editNombre;
    if (editApellido !== "") updatedData.Apellido = editApellido;

    axios
      .put(`http://167.99.118.252:5000/api/users/${selectedUser.id}`, updatedData)
      .then((response) => {
        const updatedUsers = users.map((user) =>
          user.id === selectedUser.id ? response.data : user
        );

        setUsers(updatedUsers);
        setEditDialogOpen(false);
        setEditUsername("");
        setEditEstado("");
        setEditNombre("");
        setEditApellido("");
        setSnackbarMessage("Usuario actualizado exitosamente.");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
        window.location.reload();
      })
      .catch((error) => {
        console.error("Error al actualizar el usuario:", error);
        setSnackbarMessage(
          "Error al actualizar el usuario. Por favor, intenta nuevamente más tarde."
        );
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      });
  };


  const handleDeleteUser = (id) => {
    axios
      .delete(`http://167.99.118.252:5000/api/users/${id}`)
      .then(() => {
        const updatedUsers = users.filter((user) => user.id !== id);
        setUsers(updatedUsers);
        setSnackbarMessage("Usuario eliminado exitosamente.");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
      })
      .catch((error) => {
        console.error("Error al eliminar el usuario:", error);
        setSnackbarMessage(
          "Error al eliminar el usuario. Por favor, intenta nuevamente más tarde."
        );
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      });
  };


  const handleEditDialogOpen = (user) => {
    setSelectedUser(user);
    setEditUsername(user.username);
    setEditEstado(user.Estado);
    setEditNombre(user.Nombre);
    setEditApellido(user.Apellido);
    setEditDialogOpen(true);
  };


  const handleEditDialogClose = () => {
    setEditDialogOpen(false);
    setSelectedUser(null);
    setEditUsername("");
    setEditEstado("");
    setEditNombre("");
    setEditApellido("");
  };


  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };


  const handleAddDialogOpen = () => {
    setAddDialogOpen(true);
  };


  const handleAddDialogClose = () => {
    setAddDialogOpen(false);
  };

  return (
    <MainApp>
      <Container className="container-styled">
        <Typography variant="h4" className="title">Lista de Usuarios</Typography>
        <div className="button-section">
          <Button variant="contained" color="primary" onClick={handleAddDialogOpen}>Agregar Usuario</Button>
        </div>
        <TableContainer component={Paper}>
          <Table className="table-styled">
            <TableHead>
              <TableRow>
                <TableCell className="table-header-cell">ID</TableCell>
                <TableCell className="table-header-cell">Usuario</TableCell>
                <TableCell className="table-header-cell">Estado</TableCell>
                <TableCell className="table-header-cell">Nombre</TableCell>
                <TableCell className="table-header-cell">Apellido</TableCell>
                <TableCell className="table-header-cell">Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user) => (
                <TableRow key={user.id}>
                  <TableCell>{user.id}</TableCell>
                  <TableCell>{user.username}</TableCell>
                  <TableCell>{user.Estado ? "Activo" : "Desactivado"}</TableCell>
                  <TableCell>{user.Nombre}</TableCell>
                  <TableCell>{user.Apellido}</TableCell>
                  <TableCell>
                    <Button variant="contained" color="primary" onClick={() => handleEditDialogOpen(user)}>Editar</Button>
                    <Button variant="contained" color="secondary" onClick={() => handleDeleteUser(user.id)} style={{ marginLeft: "10px" }}>Eliminar</Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>

      <Dialog open={editDialogOpen} onClose={handleEditDialogClose}>
        <DialogTitle>Editar Usuario</DialogTitle>
        <DialogContent>
          <TextField autoFocus margin="dense" label="Usuario" fullWidth value={editUsername} onChange={(e) => setEditUsername(e.target.value)} />
          <FormControl fullWidth margin="dense">
            <InputLabel>Estado</InputLabel>
            <Select value={editEstado} onChange={(e) => setEditEstado(e.target.value)}>
              <MenuItem value={1}>Activo</MenuItem>
              <MenuItem value={0}>Desactivado</MenuItem>
            </Select>
          </FormControl>
          <TextField margin="dense" label="Nombre" fullWidth value={editNombre} onChange={(e) => setEditNombre(e.target.value)} />
          <TextField margin="dense" label="Apellido" fullWidth value={editApellido} onChange={(e) => setEditApellido(e.target.value)} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditDialogClose} color="primary">Cancelar</Button>
          <Button onClick={handleEditUser} color="primary">Guardar</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={addDialogOpen} onClose={handleAddDialogClose}>
        <DialogTitle>Agregar Usuario</DialogTitle>
        <DialogContent>
          <TextField autoFocus margin="dense" label="Usuario" fullWidth value={username} onChange={(e) => setUsername(e.target.value)} />
          <TextField margin="dense" label="Contraseña" type="password" fullWidth value={password} onChange={(e) => setPassword(e.target.value)} />
          <FormControl fullWidth margin="dense">
            <InputLabel>Estado</InputLabel>
            <Select value={estado} onChange={(e) => setEstado(e.target.value)}>
              <MenuItem value={1}>Activo</MenuItem>
              <MenuItem value={0}>Desactivado</MenuItem>
            </Select>
          </FormControl>
          <TextField margin="dense" label="Nombre" fullWidth value={nombre} onChange={(e) => setNombre(e.target.value)} />
          <TextField margin="dense" label="Apellido" fullWidth value={apellido} onChange={(e) => setApellido(e.target.value)} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAddDialogClose} color="primary">Cancelar</Button>
          <Button onClick={handleAddUser} color="primary">Guardar</Button>
        </DialogActions>
      </Dialog>

      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>{snackbarMessage}</Alert>
      </Snackbar>
    </MainApp>
  );
};

export default Usuario;
