import React, { useState, useEffect } from "react";
import axios from "axios";
import {
    Typography, Container, TextField, Button, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, Snackbar,
    Alert, Dialog, DialogTitle, DialogContent, DialogActions,
} from "@mui/material";
import MainApp from './MainApp'; 
import './styles/PagStyle.css';

const Parametro = () => {
    const [params, setParams] = useState([]);
    const [paramDesc, setParamDesc] = useState("");
    const [paramValNum, setParamValNum] = useState("");
    const [paramValCha, setParamValCha] = useState("");
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const [selectedParam, setSelectedParam] = useState(null);
    const [editParamDesc, setEditParamDesc] = useState("");
    const [editParamValNum, setEditParamValNum] = useState("");
    const [editParamValCha, setEditParamValCha] = useState("");
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [addDialogOpen, setAddDialogOpen] = useState(false);

    useEffect(() => {
        axios
            .get("http://167.99.118.252:5000/api/params")
            .then((response) => {
                console.log("Datos recibidos de la API:", response.data);
                setParams(response.data);
            })
            .catch((error) => {
                console.error("Error al obtener los datos de la API:", error);
            });
    }, []);

    const handleAddParam = () => {
        if (!paramDesc || !paramValNum || !paramValCha) {
            setSnackbarMessage("Por favor, ingresa todos los campos.");
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
            return;
        }
        axios
            .post("http://167.99.118.252:5000/api/params", { MOR_PARAMDESC: paramDesc, MOR_PARAMVALNUM: paramValNum, MOR_PARAMVALCHA: paramValCha })
            .then(() => {
                axios
                    .get("http://167.99.118.252:5000/api/params")
                    .then((response) => {
                        setParams(response.data);
                        setParamDesc("");
                        setParamValNum("");
                        setParamValCha("");
                        setSnackbarMessage("Parámetro agregado exitosamente.");
                        setSnackbarSeverity("success");
                        setSnackbarOpen(true);
                        setAddDialogOpen(false);
                    })
                    .catch((error) => {
                        console.error("Error al cargar los datos de la lista de parámetros:", error);
                        setSnackbarMessage(
                            "Error al cargar los datos de la lista de parámetros. Por favor, intenta nuevamente más tarde."
                        );
                        setSnackbarSeverity("error");
                        setSnackbarOpen(true);
                    });
            })
            .catch((error) => {
                console.error("Error al agregar el parámetro:", error);
                setSnackbarMessage(
                    "Error al agregar el parámetro. Por favor, intenta nuevamente más tarde."
                );
                setSnackbarSeverity("error");
                setSnackbarOpen(true);
            });
    };
    
    const handleEditParam = () => {
        const updatedData = {
            MOR_PARAMDESC: editParamDesc,
            MOR_PARAMVALNUM: editParamValNum,
            MOR_PARAMVALCHA: editParamValCha
        };

        axios.put(`http://167.99.118.252:5000/api/params/${selectedParam.MOR_PARAMID}`, updatedData)
            .then(() => {
                const updatedParams = params.map((param) =>
                    param.MOR_PARAMID === selectedParam.MOR_PARAMID ? { ...param, ...updatedData } : param
                );
                setParams(updatedParams);
                setEditDialogOpen(false);
                setEditParamDesc("");
                setEditParamValNum("");
                setEditParamValCha("");
                setSnackbarMessage("Parámetro actualizado exitosamente.");
                setSnackbarSeverity("success");
                setSnackbarOpen(true);
            })
            .catch((error) => {
                console.error("Error al actualizar el parámetro:", error);
                setSnackbarMessage(
                    "Error al actualizar el parámetro. Por favor, intenta nuevamente más tarde."
                );
                setSnackbarSeverity("error");
                setSnackbarOpen(true);
            });
    };

    const handleDeleteParam = (id) => {
        axios
            .delete(`http://167.99.118.252:5000/api/params/${id}`) 
            .then(() => {
                const updatedParams = params.filter((param) => param.MOR_PARAMID !== id); 
                setParams(updatedParams);
                setSnackbarMessage("Parámetro eliminado exitosamente.");
                setSnackbarSeverity("success");
                setSnackbarOpen(true);
            })
            .catch((error) => {
                console.error("Error al eliminar el parámetro:", error);
                setSnackbarMessage(
                    "Error al eliminar el parámetro. Por favor, intenta nuevamente más tarde."
                );
                setSnackbarSeverity("error");
                setSnackbarOpen(true);
            });
    };

    const handleEditDialogOpen = (param) => {
        setSelectedParam(param);
        setEditParamDesc(param.MOR_PARAMDESC);
        setEditParamValNum(param.MOR_PARAMVALNUM);
        setEditParamValCha(param.MOR_PARAMVALCHA);
        setEditDialogOpen(true);
    };

    const handleAddDialogOpen = () => {
        setAddDialogOpen(true);
    };

    const handleEditDialogClose = () => {
        setEditDialogOpen(false);
        setSelectedParam(null);
        setEditParamDesc("");
        setEditParamValNum("");
        setEditParamValCha("");
    };

    const handleAddDialogClose = () => {
        setAddDialogOpen(false);
        setParamDesc("");
        setParamValNum("");
        setParamValCha("");
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    return (
        <MainApp>
            <div className="root">
                <div className="drawer-header" />
                <Container className="container-styled">
                    <Typography variant="h4" className="title">Lista de Parámetros</Typography>
                    <div className="button-section">
                        <Button variant="contained" color="primary" onClick={handleAddDialogOpen}>
                            Agregar Parámetro
                        </Button>
                    </div>
                    <TableContainer component={Paper}>
                        <Table className="table-styled">
                            <TableHead>
                                <TableRow>
                                    <TableCell className="table-header-cell">ID</TableCell>
                                    <TableCell className="table-header-cell">Descripción</TableCell>
                                    <TableCell className="table-header-cell">Valor Numérico</TableCell>
                                    <TableCell className="table-header-cell">Valor Caracter</TableCell>
                                    <TableCell className="table-header-cell">Acciones</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {params.map((param) => (
                                    <TableRow key={param.MOR_PARAMID}>
                                        <TableCell>{param.MOR_PARAMID}</TableCell>
                                        <TableCell>{param.MOR_PARAMDESC}</TableCell>
                                        <TableCell>{param.MOR_PARAMVALNUM}</TableCell>
                                        <TableCell>{param.MOR_PARAMVALCHA}</TableCell>
                                        <TableCell>
                                            <Button variant="contained" color="primary" onClick={() => handleEditDialogOpen(param)}>Editar</Button>
                                            <Button variant="contained" color="secondary" onClick={() => handleDeleteParam(param.MOR_PARAMID)}>Eliminar</Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Container>
                <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                    <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>{snackbarMessage}</Alert>
                </Snackbar>
                <Dialog open={editDialogOpen} onClose={handleEditDialogClose}>
                    <DialogTitle>Editar Parámetro</DialogTitle>
                    <DialogContent>
                        <TextField label="Descripción" variant="outlined" value={editParamDesc} onChange={(e) => setEditParamDesc(e.target.value)} fullWidth margin="normal" />
                        <TextField label="Valor Numérico" variant="outlined" value={editParamValNum} onChange={(e) => setEditParamValNum(e.target.value)} fullWidth margin="normal" />
                        <TextField label="Valor Caracter" variant="outlined" value={editParamValCha} onChange={(e) => setEditParamValCha(e.target.value)} fullWidth margin="normal" />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleEditDialogClose} color="primary">Cancelar</Button>
                        <Button onClick={handleEditParam} color="primary">Guardar Cambios</Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={addDialogOpen} onClose={handleAddDialogClose}>
                    <DialogTitle>Agregar Parámetro</DialogTitle>
                    <DialogContent>
                        <TextField label="Descripción" variant="outlined" value={paramDesc} onChange={(e) => setParamDesc(e.target.value)} fullWidth margin="normal" />
                        <TextField label="Valor Numérico" variant="outlined" value={paramValNum} onChange={(e) => setParamValNum(e.target.value)} fullWidth margin="normal" />
                        <TextField label="Valor Caracter" variant="outlined" value={paramValCha} onChange={(e) => setParamValCha(e.target.value)} fullWidth margin="normal" />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleAddDialogClose} color="primary">Cancelar</Button>
                        <Button onClick={handleAddParam} color="primary">Agregar</Button>
                    </DialogActions>
                </Dialog>
            </div>
        </MainApp>
    );
};

export default Parametro;
